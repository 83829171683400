// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1tFOd{position:relative;display:flex;min-height:600px;justify-content:center}._1tFOd img{height:100%!important;-o-object-fit:cover;object-fit:cover}._1tFOd [id^=adfox]{width:100%!important;height:100%;overflow-x:clip}._1tFOd.rVUvP{min-height:314px;padding:32px 0;border-color:#e2e2e2;border-style:solid;border-width:0 24px;background:#e2e2e2}._1tFOd:not(.yNYpD):before{position:absolute;z-index:2;top:0;left:0;display:flex;width:100%;height:32px;align-items:center;justify-content:center;color:#989898;content:attr(data-title);font-size:12px;line-height:16px}._1tFOd .bNWqD,.rioI4{width:100%}.rioI4{top:0;left:0;height:100%;padding:32px 16px;border:1px solid #e2e2e2;background-color:#e2e2e2;border-radius:8px;color:#f02d37}.rioI4,.xnO7R{position:absolute}.xnO7R{top:50%;left:50%;transform:translate(-50%,-50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardAdfox": "_1tFOd",
	"row": "rVUvP",
	"column": "yNYpD",
	"adfox": "bNWqD",
	"holder": "rioI4",
	"loader": "xnO7R"
};
module.exports = ___CSS_LOADER_EXPORT___;
