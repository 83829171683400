import { computed, defineComponent, ref, useCssModule } from '@nuxtjs/composition-api';
import { ADFOX_FETCH_STATES } from '~/components/Adfox';
import { SHOULD_DISABLE } from '~/plugins/adfox';
export default defineComponent({
    name: 'CardAdfox',
    props: {
        options: {
            default: () => {
                return {};
            },
            type: Object,
        },
        size: {
            required: true,
            type: Object,
        },
    },
    setup(props) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        /**
         * Root element
         */
        const rootEl = ref();
        /**
         * Adfox states
         */
        const currentStates = ref({ ...ADFOX_FETCH_STATES });
        /**
         * Card size type
         */
        const type = computed(() => {
            if (props.size.width > 1) {
                return 'row';
            }
            return 'column';
        });
        const shouldRender = ref(!SHOULD_DISABLE);
        /**
         * Adfox onComplete
         */
        function onComplete({ state }) {
            currentStates.value = state;
            shouldRender.value = !(state.withError || state.withStub);
            if (type.value === 'row' && rootEl.value) {
                rootEl.value.style.minHeight = 'auto';
                rootEl.value.style.minHeight = rootEl.value.clientHeight + 'px';
            }
        }
        return {
            css,
            currentStates,
            onComplete,
            rootEl,
            shouldRender,
            type,
        };
    },
});
